:root {
  --main-color: #FFF;
  --main-secondary-color: #ec4646;

  --main-color-opaque: #ffffffc7;
  --main-secondary-color-opaque: #ec46466d; 

  --light-bg: #F2F2F2;

  --text-color-dark: #373737;
  --text-color-light: #4a4a4a;

  --button-bg: var(--main-secondary-color);
  --button-color: var(--main-color);
  --button-bg-darker: #9f2e2e;

  --shadow-color: #00000030;
  --shadow-elevation-medium:
    0px 0.2px 0.2px var(--shadow-color),
    0px 0.6px 0.7px -0.8px var(--shadow-color),
    0px 1.5px 1.7px -1.7px var(--shadow-color),
    -0.1px 3.6px 4.1px -2.5px var(--shadow-color);

  --shop-filter-bg: #ffd150;
  --shop-filter-color: #00000091;
  --shop-filter-shadow: inset 0px -1px 0px 0px #c49f39;

  --cat-filter-bg: #96caff;
  --cat-filter-color: #000000b5;
  --cat-filter-shadow: inset 0px -1px 0px 0px #567ea8;

  --deal-filter-bg: #f2f2f2;
  --deal-filter-color: #666;
  --deal-filter-shadow: inset 0px -1px 0px 0px #66666678;

  --filter-no-interest-bg: #F2F2F2;
  --filter-no-interest-color: #b9b9b9;

  --filter-counter-bg: #FFFFFF36;
  --active-filter-shadow: inset 0px -1px 0px 0px #005305;

  --radial-product-bg: radial-gradient(circle, rgba(255, 255, 255, 1) 40%, rgba(255, 255, 255, 1) 63%, rgb(247, 247, 247) 100%);
  --product-bg: rgb(255, 255, 255);
  --product-name-color: #35322a;
  --product-info-color: #686868;
  --product-deal-bg: #F2F2F2;
  --product-deal-color: #666;
  --product-cat-bg: #FFF;
  --product-cat-color: #3e5d8b;

  --input-color: #444;
  --input-bg: #FFF;
}

:root.dark {
  --main-color: #393939;
  --main-secondary-color: #ec4646;

  --main-color-opaque: #393939de;

  --light-bg: #666;

  --text-color-dark: #fff;
  --text-color-light: #c8c8c8;


  --button-bg: var(--main-secondary-color);
  --button-color: var(--main-color);
  --button-bg-darker: #8a2525;

  --deal-filter-bg: #aeaeae;
  --deal-filter-color: #3d3d3d;
  --deal-filter-shadow: inset 0px -1px 0px 0px #66666678;

  --filter-no-interest-bg: #444;
  --filter-no-interest-color: #777;

  --filter-counter-bg: #FFFFFF36;

  --radial-product-bg: #666;
  --product-bg: rgb(40, 40, 40);
  --product-name-color: #fff;
  --product-info-color: #fff;
  --product-deal-bg: #444;
  --product-deal-color: #fff;
  --product-cat-bg: #444;
  --product-cat-color: #96caff;

  --input-color: var(--text-color-dark);
  --input-bg: var(--main-color);
}

body,
html {
  padding: 0px;
  margin: 0px;
  height: 100%;
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
  background: var(--main-color);
  user-select: none;
}

button {
  border: none;
}

.app-wrap {
  min-height: 100vh;
  margin: 0px auto;
  max-width: 2500px;
  position: relative;
}

header.title-header {
  display: block;
  background: var(--main-color-opaque);
  backdrop-filter: blur(4px);
  padding: 0px 0px 10px 10px;
  width: 100%;
  position: fixed;
  z-index: 910;
  top: 0px;
}

a.title-sober {
  text-decoration: none;
  display: block;
  position: relative;
  margin: 10px 0px 0px 0px;
  text-align: left;
  overscroll-behavior-y: none;
}

a.title-sober span {
  display: inline-block;
  text-align: center;
  position: relative;
  width: 235px;
  font-weight: 800;
  font-size: 1.5em;
  border-radius: 8px;
  text-transform: uppercase;
  padding: 0px 10px 0px 10px;
  line-height: 40px;
  background: var(--button-bg);
  color: var(--button-color);
}

footer {
  text-align: center;
  height: 75vh;
  position: relative;
  padding-top: 25vh;
}

footer .about {
  display: block;
  width: 200px;
  position: relative;
  background: #C2D9FD;
  margin: 15px auto;
  padding: 8px 10px 8px 10px;
  border-radius: 15px;
  transform: rotate(3deg) translateY(-5px);
  box-shadow: 0px 0px 0px 4px #FFF;
  font-size: .8em;
  color: #4c5573;
  text-decoration: underline;
  text-decoration-thickness: 2px;
  font-style: normal;
  font-weight: 800;
}

footer .donate {
  display: block;
  width: 250px;
  position: relative;
  background: #F4CA43;
  margin: 15px auto;
  padding: 10px;
  border-radius: 15px;
  transform: rotate(-3deg);
  font-size: .8em;
  color: #6a5a19;
}

footer a {
  text-decoration: none;
}

footer .donate i.ri-paypal-fill {
  font-size: 2.2em;
  color: #003087;
  text-shadow: 1px 1px #FFF;
  position: absolute;
  left: -10px;
}

footer .donate i.donate-focus {
  text-decoration: underline;
  text-decoration-thickness: 2px;
  font-style: normal;
  color: #665202;
  font-weight: 800;
}

footer .title {
  text-transform: uppercase;
  display: inline-block;
  width: 190px;
  text-align: center;
  cursor: pointer;
  color: var(--button-color);
  font-weight: 800;
  font-size: 1.5em;
  border-radius: 8px;
  padding: 0px 10px 0px 10px;
  background: var(--button-bg);
  line-height: 40px;
  position: relative;
}

footer .sub-title-wrap {
  display: block;
  margin: 15px 0px 0px 0px;
  position: relative;
  width: 100%;

}

footer .sub-title {
  color: var(--text-color-dark);
  display: block;
  margin: 0 auto;
  padding: 0px 15px 0px 15px;
  text-align: center;
  max-width: 500px;
  font-size: 1.6em;
  font-weight: 800;
}

header.header-title {
  background: var(--button-bg);
  margin: 0px auto;
  position: relative;
  font-weight: 800;
  text-align: center;
  text-transform: uppercase;
  z-index: 800;
  width: 100%;
  display: block;
  top: 60px;
  padding: 10px 0px 10px 0px;
  margin: 0px 0px 10px 0px;
  color: var(--button-color);
  min-height: 30px;
}

header.header-title span {
  vertical-align: middle;
  line-height: 30px;
}

header.header-title.search {
  top: 0px;
  margin: 0px 0px 0px 0px;
}

.even-geduld {
  text-align: center;
  color: #FFF;
  margin: 0px auto;
  height: 100vh;
  line-height: 50vh;
}

.even-geduld .dot-dot-dot::before {
  animation: dotdotdot 3000ms infinite;
  display: inline-block;
  content: "";
  width: 80px;
  text-align: left;
}

.even-geduld-image {
  height: 100px;
  background: rgba(255, 255, 255, 1);
  background: radial-gradient(circle, rgba(255, 255, 255, 1) 40%, rgba(255, 255, 255, 1) 63%, rgb(247, 247, 247) 100%);

}

.new {
  position: absolute;
  background: rgb(0, 127, 56);
  background: linear-gradient(180deg, rgba(0, 127, 56, 1) 0%, rgba(63, 189, 29, 1) 100%);
  color: #FFF;
  padding: 2px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 4px;
  top: -5px;
  right: -45px;
  text-align: center;
  width: 87px;
  z-index: 100;
  line-height: 15px;
  transform: rotate(2deg);
}

.bottom-buttons {
  padding: 10px;
  border-radius: 8px;
  position: fixed;
  top: 0px;
  display: table;
  right: 0px;
  z-index: 920;
}

.bottom-buttons .button-cell.go-home-nav-element {
  display: none;
}

.bottom-buttons .button-cell {
  display: table-cell;
  position: relative;
}

.bottom-buttons .button-cell a {
  text-decoration: none;
}

.bottom-buttons .button {
  margin: 0px 0px 0px 5px;
  cursor: pointer;
  line-height: 40px;
  color: var(--main-color);
  background: var(--main-secondary-color);
  text-align: center;
  padding: 0px 10px 0px 10px;
  font-size: 1.2em;
  height: 40px;
  border-radius: 8px;
  overflow: hidden;
}

.bottom-buttons .button span {
  margin: 0px 0px 0px 5px;
}

.bottom-buttons .button-tag {
  display: inline-block;
  padding: 0px;
  vertical-align: top;
  margin-left: 7px;
  font-size: .7em;
  font-weight: 600;
}

.jumpy {
  animation: jumpy 1200ms;
}

.dialog-wrap {
  top: 0px;
  left: 0px;
  display: none;
  background: #0000008a;
  width: 100vw;
  height: 100vh;
  position: fixed;
  backdrop-filter: blur(2px);
}

#search-dialog-on {
  display: block;
  z-index: 970;
}

#share-dialog-on .share-dialog {
  display: block;
}

#copy-dialog-on .copy-dialog {
  display: block;
}

#info-dialog-on .info-dialog {
  display: block;
}

#share-dialog-on,
#copy-dialog-on,
#single-dialog-on,
#info-dialog-on {
  display: block;
  z-index: 990;
  /* high index so it is above the search bar */
}

.share-dialog,
.copy-dialog,
.info-dialog {
  display: none;
  position: relative;
  background: var(--main-color);
  max-width: 400px;
  margin: 0px auto;
  top: 25vh;
  box-shadow: var(--shadow-elevation-medium);
  border-radius: 8px;
  text-align: center;
  animation: modal 500ms;
}

.info-dialog {
  top: 10vh;
  padding-bottom: 5px;
}

.info-dialog p {
  font-size: .8em;
  padding: 0px 15px 0px 15px;
  text-align: center;
}

.share-dialog .share-title,
.copy-dialog .share-title,
.info-dialog .info-dialog-title {
  display: block;
  padding: 30px 15px 0px 15px;
  color: var(--text-color-dark);
  font-weight: 800;
  font-size: 1.6em;
}

.share-dialog .share-info,
.copy-dialog .share-info,
.info-dialog .info-dialog-subtitle {
  color: var(--text-color-dark);
  font-weight: 300;
  font-size: .8em;
  max-width: 75%;
  display: inline-block;
  padding-top: 10px;
}

.copy-dialog .list-share {
  padding: 10px 0px 10px 0px;
  margin: 10px 0px 10px 0px;
  background: var(--button-bg);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

}

.copy-dialog .list-share textarea {
  font-weight: 800;
  border: none;
  outline: none;
  padding: 10px 10px 10px 10px;
  width: calc(100% - 40px);
  height: 25vh;
  margin: 0px;
  max-height: 500px;
  min-height: 100px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  resize: vertical;
  color: var(--input-color);
  background: var(--input-bg);
  box-shadow: inset 0px -1px 0px 0px var(--button-bg);

}

.copy-dialog .list-share span.list-copy {
  cursor: pointer;
  display: block;
  line-height: 40px;
  padding: 0px 10px 0px 10px;
  background: var(--button-bg-darker);
  color: var(--button-color);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  right: 0px;
  position: relative;
  margin: -1px 10px 0px 10px;
}

.copy-dialog .list-share span.list-copy .label {
  display: inline-block;
  font-size: .8em;
  vertical-align: top;
  margin-left: 5px;
}

.share-dialog .url-share {
  display: table;
  background: var(--button-bg);
  margin: 10px 0px 10px 0px;
  padding: 10px 5px 10px 5px;
  position: relative;
  width: calc(100% - 10px);
  border-spacing: 0px;
}

.share-dialog .url-share input {
  font-weight: 800;
  padding: 0px 10px 0px 10px;
  line-height: 40px;
  border: none;
  width: calc(100% - 130px);
  display: inline-block;
  outline: none;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  vertical-align: top;
  background: var(--input-bg);
  color: var(--input-color);
}

.share-dialog .url-share .url-copy {
  cursor: pointer;
  display: inline-block;
  line-height: 37px;
  padding: 0px 10px 0px 10px;
  background: var(--button-bg-darker);
  color: var(--button-color);
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
}

.share-dialog .url-share .url-copy .label {
  display: inline-block;
  font-size: .8em;
  vertical-align: top;
  margin-left: 5px;
}

.copy-confirm {
  display: none;
  background: #000;
  position: absolute;
  bottom: -25px;
  color: #FFF;
  border-radius: 8px;
  padding: 0px 10px 0px 10px;
  line-height: 30px;
  width: 150px;
  left: calc(50% - 85px);
}

.share-dialog .url-share .hide,
.copy-dialog .list-share .hide {
  display: block;
  animation: floatIn 3s;
}

.dialog-wrap .socials {
  padding: 0px 0px 10px 0px;
  display: table;
  width: 100%;
  font-size: 1.2em;
}

.dialog-wrap .socials a {
  display: table-cell;
}

.dialog-wrap .socials a.telegram {
  color: #0088cc;
}

.dialog-wrap .socials a.whatsapp {
  color: #075e54;
}

.dialog-wrap .socials a.twitter {
  color: #1da1f2;
}

.dialog-wrap .socials a.reddit {
  color: #ff4500;
}

.dialog-wrap .socials a.linkedin {
  color: #0077b5;
}

.dialog-wrap .socials i {
  padding: 10px;
  font-size: 1.5em;
  display: inline-block;
}

b.label {
  display: inline-block;
  padding: 5px 10px 5px 10px;
  border-radius: 8px;
  background: #ededed;
  color: #696969;
  animation: popOut 500ms;
  margin: 0px 10px 0px 0px;
  box-shadow: inset 0px -1px 0px 0px #0000006b;
}

.filter {
  position: sticky;
  z-index: 900;
  top: 0px;
  width: 100%;
  background: var(--main-color-opaque);
  backdrop-filter: blur(4px);
  overflow: hidden;
}

#filter-dialog {
  padding: 10px 0px 0px 0px;
}

.filter-wrap {
  max-width: 1590px;
  margin: 0px auto;
}

.bookmark-info {
  color: var(--button-bg);
  padding: 10px 0px 10px 10px;
  display: block;
  text-align: right;
}

.bookmark-info span {
  vertical-align: top;
}

.toggle-on {
  display: block !important;
  animation: popUpDialog 200ms;
}

.search-dialog {
  z-index: 980;
  position: sticky;
  top: 60px;
}

input.filter-search {
  width: calc(100% - 50px);
  max-width: 400px;
  padding: 10px 15px 10px 15px;
  position: relative;
  border-radius: 15px;
  font-size: 16px;
  background: var(--input-bg);
  color: var(--input-color);
  font-weight: 800;
  outline: none;
  border: none;
}

.filter-search-recent {
  overflow-x: scroll;
  white-space: nowrap;
  background: var(--main-color-opaque);
  backdrop-filter: blur(4px);
  padding: 10px 10px 10px 10px;
  position: relative;
  text-align: center;
  opacity: 0;
  animation: showUp 500ms;
  animation-fill-mode: forwards;
  animation-delay: 500ms;
}

.filter-search-recent.recent-dummy {
  padding: 0px;
  margin-bottom: 10px;
  overflow: hidden;
}

.filter-search-recent span.label {
  padding: 0px 10px 0px 0px;
  display: inline-block;
  font-size: .8em;
  font-weight: 800;
  overflow: hidden;
  color: var(--text-color-light);
  vertical-align: middle;
}

.filter-search-recent div.recent-search {
  display: inline-block;
  color: var(--text-color-dark);
  border-radius: 15px;
  margin: 0px 5px 0px 0px;
  font-weight: 800;
}

.filter-search-recent div.recent-search .term {
  display: inline-block;
  cursor: pointer;
  line-height: 30px;
  padding: 0px 0px 0px 10px;
  vertical-align: middle;
  font-size: .8em;
}

.filter-search-recent div.recent-search i.delete {
  font-style: normal;
  float: right;
  cursor: pointer;
  opacity: 0.6;
  text-align: center;
  vertical-align: middle;
  width: 35px;
  line-height: 30px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  color: var(--text-color-light);
}

.filter-search-recent div.recent-search:last-child {
  margin: 0px 10px 0px 0px;
}

.filter-deal,
.filter-cat,
.filter-shop {
  display: inline-block;
  white-space: nowrap;
  overflow-x: auto;
  width: calc(100% - 10px);
  padding: 0px 0px 0px 10px;
  margin-top: 0px;
  margin-bottom: 5px;
  float: left;
  position: relative;
}

.filter-view-toggle {
  float: left;
  line-height: 37px;
  display: none;
  width: 30px;
  font-size: 1.2em;
  background: none;
  text-align: left;
  padding: 0;
  color: var(--text-color-dark);
  cursor: pointer;
  opacity: 0;
  animation: showUp 500ms;
  animation-fill-mode: forwards;
  animation-delay: 2000ms;
}

.as-grid {
  white-space: normal;
  z-index: 999;
  max-height: calc(100vh - 200px);
  overflow-y: scroll;
  padding-bottom: 0px;
  padding-top: 0px;
  margin: 0px 0px 0px 0px;
  animation: popout 250ms ease-out;
}

.as-grid.filter-cat .cat-list button {
  padding: 5px 0px 5px 10px;
  margin: 0px 0px 5px 5px;
}

.as-grid.filter-cat .cat-list button.filter-no-interest:not(#active-category) {
  padding: 5px 10px 5px 10px;
}

.as-grid.filter-cat .cat-list #active-category.filter-no-interest {
  padding: 5px 10px 5px 10px;
}

.as-grid.filter-cat .cat-list button:last-child {
  margin: 0px 0px 5px 5px;
}

.filter-cat .cat-list button,
.filter-shop button,
.filter-deal button {
  display: inline-block;
  outline: none;
  position: relative;
  z-index: 700;
  border-radius: 15px;
  padding: 15px 15px 15px 15px;
  margin: 0px 5px 0px 0px;
  font-size: .8em;
  font-weight: 800;
  cursor: pointer;
  scroll-snap-align: start;
}

.filter-shop button {
  text-transform: capitalize;
  background: var(--shop-filter-bg);
  color: var(--shop-filter-color);
  overflow: hidden;
  padding: 10px 0px 10px 10px;
  opacity: 0;
  animation: showFilters 500ms;
  animation-fill-mode: forwards;
  animation-delay: 500ms;
}

.filter-cat .cat-list button {
  background: var(--cat-filter-bg);
  color: var(--cat-filter-color);
  padding: 10px 0px 10px 10px;
  overflow: hidden;
  opacity: 0;
  animation: showFilters 500ms;
  animation-fill-mode: forwards;
  animation-delay: 1000ms;

}

.filter-deal button {
  background: var(--deal-filter-bg);
  color: var(--deal-filter-color);
  padding: 8px 10px 8px 10px;
  margin: 0px 5px 5px 0px;
  opacity: 0;
  animation: showFilters 500ms;
  animation-fill-mode: forwards;
  animation-delay: 1500ms;
}

.filter-shop button:last-child,
.filter-cat .cat-list button:last-child,
.filter-deal button:last-child {
  margin: 0px 10px 0px 0px;
}

.filter-cat .cat-list button.filter-no-interest:not(#active-category) {
  filter: grayscale();
  color: var(--filter-no-interest-color);
  padding: 10px 10px 10px 10px;
  background: var(--light-bg);
}

.filter-cat .cat-list #active-category.filter-no-interest {
  filter: grayscale();
  padding: 10px 10px 10px 10px;
}

.filter-cat .cat-list i.counter {
  background: var(--filter-counter-bg);
  color: var(--text-color-light);
  text-shadow: none;
  font-style: normal;
  padding: 10px 5px 10px 5px;
  margin-left: 5px;
}

.filter-shop button.filter-shop-no-interest:not(#active-category) {
  filter: grayscale();
  color: var(--filter-no-interest-color);
  padding: 10px 10px 10px 10px;
  background: var(--light-bg);
}

.filter-shop #active-shop.filter-shop-no-interest {
  padding: 10px 10px 10px 10px;
}

.filter-shop i.counter {
  background: var(--filter-counter-bg);
  color: var(--text-color-light);
  text-shadow: none;
  font-style: normal;
  padding: 10px 5px 10px 5px;
  margin-left: 5px;
}

#active-deal,
#active-shop,
#active-category {
  color: #FFF !important;
  text-shadow: 1px 1px #005305;
  background: #16b000 !important;
  position: sticky;
  right: 10px;
  left: 0px;
  z-index: 800;
}

#filter-button {
  display: inline-block;
  background: var(--main-color);
  color: #fff;
  padding: 5px 10px 5px 10px;
  font-weight: 800;
  text-align: center;
  cursor: pointer;
  z-index: 900;
  margin: 10px 10px 10px 0px;
  border-radius: 8px;
  box-shadow: inset 0px 0px 0px 1px #b25656;
  text-shadow: 0px 1px #b25656;
  min-width: 25px;
}

.info {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-weight: 800;
  font-size: 1.5em;
  color: #FFF;
  text-shadow: 2px 3px #b25656;
  text-align: center;
  margin: 0px auto;
  max-width: 1200px;
  padding: 15px;
  text-transform: uppercase;
}

.flex-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  padding: 0px 0px 0px 15px;
  max-width: 1590px;
  min-height: 50vh;
  margin: 0px auto;
}

.flex-container .show-more {
  text-align: center;
  width: 100%;
  margin: 15px 15px 0px 0px;
}

.flex-container .show-more span {
  background: var(--button-bg);
  display: inline-block;
  border-radius: 8px;
  padding: 10px 45px 10px 45px;
  animation: attention 12s infinite;
  color: var(--button-color);
  cursor: pointer;
  text-transform: uppercase;
}

.no-offers {
  display: block;
  position: relative;
  padding: 60px 10px 10px 10px;
  color: var(--text-color-dark);
  max-width: 300px;
  text-align: center;
  margin: 0px auto;
}

.no-offers-search {
  display: block;
  position: relative;
  padding: 60px 10px 10px 10px;
  color: var(--text-color-dark);
  max-width: 300px;
  text-align: center;
  margin: 0px auto;
}

.not-found {
  display: block;
  margin: 0px auto;
  padding: 100px 10px 10px 10px;
  color: var(--text-color-dark);
  max-width: 250px;
  text-align: center;
}

.notify-wrap {
  font-size: 1.6em;
  font-weight: 800;
  padding: 15px 0px 0px 0px;
}

.notify-explainer {
  color: var(--text-color-dark);
  font-size: .8em;
  padding: 15px 0px 15px 0px;
}

.notify-explainer .ri-bookmark-line {
  display: inline-block;
  color: #777;
  background: #c4c4c433;
  border-radius: 8px;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  font-size: .9em;
}

.flex-item {
  z-index: 100;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
  border-radius: 15px;
  overflow: hidden;
  margin: 0px 15px 15px 0px;
  width: 200px;
  position: relative;
  max-width: 300px;
  animation: showUp 500ms;
}

.flex-item-single {
  margin: 0px auto !important;
  top: 25%;
  transform: scale(2);
}

.home-button-item-single {
  color: var(--button-bg);
  background: var(--button-color);
  display: inline-block;
  padding: 0px 10px 0px 10px;
  margin: 10px;
  border-radius: 15px;
  line-height: 40px;
  font-size: 1em;
  font-weight: 600;
}

.product-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--main-secondary-color-opaque);
  z-index: 100;
}

.product-image {
  background: var(--product-bg);
  background: var(--radial-product-bg);
  min-height: 100px;
  margin: 0;
  display: flex;
  overflow: hidden;
  text-align: center;
  align-items: center;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  justify-content: center;
  opacity: 0;
  animation: showUp 500ms;
  animation-fill-mode: forwards;
  animation-delay: 500ms;
}

.product-image img {
  max-width: 65%;
  transform: scale(1.2);
}

.product-image img.default-image {
  max-width: 25%;
  opacity: .5;
}

.product-name {
  background: var(--product-bg);
  background: var(--radial-product-bg);
  display: block;
  color: var(--product-name-color);
  padding: 10px 10px 10px 10px;
  font-weight: 800;
  overflow-wrap: break-word;
  text-align: center;
}

.product-title {
  display: block;
}

a.product-link {
  display: block;
  color: inherit;
  text-decoration: none;
}

a.product-link:hover .product-title {
  text-decoration: underline;
  text-decoration-thickness: 2px;
}

.product-category {
  background: var(--product-cat-bg);
  color: var(--product-cat-color);
  display: inline-block;
  font-size: .6em;
  font-weight: 800;
  left: 5px;
  line-height: 18px;
  padding: 0 5px;
  position: absolute;
  border-radius: 4px;
  top: 35px;
  z-index: 1;
}

.product-info {
  font-weight: 300;
  font-size: .7em;
  overflow-wrap: break-word;
  color: var(--producht-info-color);
}

.product-deal {
  padding: 10px 0px 10px 0px;
  position: relative;
  display: block;
  text-align: center;
  color: var(--product-deal-color);
  font-weight: 800;
  background: var(--product-deal-bg);
  box-shadow: inset 0px 5px 8px -5px #00000008;
  margin: 0px 0px 0px 0px;
}

.product-bookmark {
  outline: none;
  cursor: pointer;
  position: absolute;
  right: 0px;
  bottom: 0px;
  color: #777;
  background: #c4c4c433;
  border-radius: 8px;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  font-size: .8em;
  opacity: 0;
  animation: showUp 500ms;
  animation-fill-mode: forwards;
  animation-delay: 1000ms;
}

.product-bookmark-true {
  color: var(--button-color);
  background: var(--button-bg);
  opacity: 0;
  animation: showUp 500ms;
  animation-fill-mode: forwards;
  animation-delay: 1000ms;
}

.product-header {
  display: flex;
  justify-content: space-between;
  line-height: 30px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.product-header.lidl {
  background: #fff000;
  color: #0050aa;
}

.product-header.lidl .product-date {
  color: #000000d1;
  text-shadow: none;
}

.product-header.jumbo {
  background: #eeb717;
  color: #FFF;
}

.product-header.jumbo .product-date {
  color: #000000d9;
  text-shadow: none;
}

.product-header.poiesz {
  background: #f18700;
  color: #fdf5dc;
}

.product-header.ah {
  background: #00ade6;
  color: #FFF;
}

.product-header.aldi {
  background: #202b78;
  color: #FFF;
}

.product-header.dirk {
  background: #bd4451;
  color: #FFF;
}

.product-header.dekamarkt {
  background: #e20613;
  color: #FFF;
}

.product-header.spar {
  background: #007a33;
  color: #FFF;
}

.product-header.ekoplaza {
  color: #FFF;
  background: #6d1f80;
  text-decoration: none;
}

.product-header.plus {
  color: #FFF;
  background: #80bd1d;
  text-decoration: none;
}

.product-header.hoogvliet {
  background: #00ace7;
  color: #FFF;
}

.product-header.hoogvliet .product-shop {
  text-decoration: underline;
  text-decoration-color: #e30613;
  text-underline-offset: 2px;
  text-decoration-thickness: 2px;
}


.product-date {
  display: inline-block;
  font-size: .8em;
  opacity: .7;
  margin: 0px 0px 0px 0px;
  padding: 0px 10px 0px 0px;
}

.date-now {
  font-style: italic;
}

.product-shop {
  display: inline-block;
  z-index: 800;
  left: 0px;
  top: 0px;
  font-weight: 800;
  font-size: .8em;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 10px;
  text-transform: capitalize;
}

.product-price::after {
  content: " €";
  opacity: .8;
}

.product-price {
  background: #2fb000;
  border-radius: 8px;
  color: #fff;
  display: inline-block;
  font-weight: 800;
  line-height: 30px;
  padding: 0 10px;
  position: absolute;
  right: 5px;
  text-align: center;
  top: 35px;
  z-index: 1;
}

.product-unknown {
  background: #CCCCCC7A;
}

.product-percentage::before {
  content: "- ";
  opacity: .8;
}

.product-percentage::after {
  content: " %";
  opacity: .8;
}

.product-percentage {
  background: #478631;
  border-radius: 8px;
  color: #fff;
  display: inline-block;
  font-weight: 800;
  line-height: 24px;
  padding: 0 10px 0px 10px;
  position: absolute;
  right: 5px;
  text-align: center;
  top: 70px;
  font-size: .9em;
  z-index: 1;
}

.regular-container h2 {
  font-size: 1.6em;
  color: var(--text-color-dark);
  display: block;
  text-align: center;
  margin: 15px 15px 0px 15px;
}

.regular-container .sub-text {
  /* Gebruik onder de h2 titel */
  color: var(--text-color-dark);
  font-size: .8em;
  max-width: 600px;
  padding: 0px 30px 15px 30px;
  margin: 0px auto;
  text-align: center;
}

.dark-switch {
  justify-content: center;
  display: flex;
  flex-direction: row;
  padding: 0px 15px 5px 15px;
}

.dark-switch-option {
  max-width: 150px;
  height: 80px;
  overflow: hidden;
  padding: 30px 15px 0px 15px;
  margin: 0px 0px 0px 0px;
  background: #c9c9c9;
  border-radius: 8px;
  cursor: pointer;
}

.dark-switch-option:first-child {
  margin: 0px 15px 0px 0px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.dark-switch-option:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.dark-switch-option .theme-dark {
  box-shadow: 0px 0px 0px 2px #000;
  background: #222;
  padding: 10px;
}

.dark-switch-option .theme-dark div.title {
  font-size: .9em;
  background: var(--button-bg);
  color: #222;
  font-weight: 800;
  overflow: hidden;
  margin: 0px 0px 5px 0px;
  border-radius: 4px;
  text-transform: uppercase;
  padding: 0px 10px 0px 10px;
}

.dark-switch-option .theme-dark div.label-shop {
  width: 35px;
  height: 15px;
  display: inline-block;
  border-radius: 8px;
  background: var(--shop-filter-bg);
}

.dark-switch-option .theme-dark div.label-cat {
  width: 35px;
  height: 15px;
  margin: 0px 0px 0px 5px;
  display: inline-block;
  border-radius: 8px;
  background: var(--cat-filter-bg);
}

.dark-switch-option .theme-dark div.deal {
  background: #666;
  width: 100%;
  height: 35px;
  margin: 5px 0px 0px 0px;
  display: block;
  border-radius: 8px;
}

.dark-switch-option .theme-light {
  box-shadow: 0px 0px 0px 2px #CCC;
  background: #FFF;
  padding: 10px;
}

.dark-switch-option .theme-light div.title {
  font-size: .9em;
  background: var(--button-bg);
  color: #FFF;
  font-weight: 800;
  overflow: hidden;
  margin: 0px 0px 5px 0px;
  border-radius: 4px;
  text-transform: uppercase;
  padding: 0px 10px 0px 10px;
}

.dark-switch-option .theme-light div.label-shop {
  width: 35px;
  height: 15px;
  display: inline-block;
  border-radius: 8px;
  background: #ffd150;
}

.dark-switch-option .theme-light div.label-cat {
  width: 35px;
  height: 15px;
  margin: 0px 0px 0px 5px;
  display: inline-block;
  border-radius: 8px;
  background: #96caff;
}

.dark-switch-option .theme-light div.deal {
  background: #f2f2f2;
  width: 100%;
  height: 35px;
  margin: 5px 0px 0px 0px;
  display: block;
  border-radius: 8px;
}

.dark-switch-option a.title-sober span {
  display: inline-block;
  text-align: center;
  position: relative;
  top: 0px;
  width: 80px;
  font-weight: 800;
  font-size: .8em;
  border-radius: 8px;
  text-transform: uppercase;
  padding: 0px 10px 0px 10px;
  line-height: 20px;
  background: var(--button-bg);
  color: var(--button-color);
}

.shop-pick, .category-pick {
  justify-content: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0px auto;
  max-width: 400px;
  padding: 0px 15px 0px 15px;
  color: var(--text-color-dark);
}

.shop-pick .shop {
  cursor: pointer;
  display: inline-block;
  background: var(--shop-filter-bg);
  color: var(--shop-filter-color);
  border-radius: 15px;
  padding: 10px 10px 10px 10px;
  margin: 0px 5px 5px 0px;
  font-size: .8em;
  font-weight: 800;
}

.shop-pick .shop.ignore-shop {
  background: var(--filter-no-interest-bg);
  color: var(--filter-no-interest-color);
}

.category-pick .category {
  cursor: pointer;
  display: inline-block;
  background: var(--cat-filter-bg);
  color: var(--cat-filter-color);
  border-radius: 15px;
  padding: 10px 10px 10px 10px;
  margin: 0px 5px 5px 0px;
  font-size: .8em;
  font-weight: 800;
}

.category-pick .category.ignore-category {
  background: var(--filter-no-interest-bg);
  color: var(--filter-no-interest-color);
}

@media screen and (max-width: 1000px) {
  .flex-item {
    min-width: 220px;
  }

  /* Change title and bottom navigation to mobile friendly */
  @media screen and (max-width: 850px) {

    header.title-header {
      display: inherit;
      background: none;
      padding: 0px;
      width: inherit;
      position: relative;
      z-index: inherit;
    }

    a.title-sober {
      text-align: center;
    }

    a.title-sober span {
      position: sticky;
    }

    header.header-title {
      position: sticky;
      z-index: 900;
      top: 0px;
      margin: 10px 0px 10px 0px;
    }

    header.header-title.search {
      margin: 10px 0px 0px 0px;
    }

    .search-dialog {
      top: 0px;
    }

    .filter-search-recent {
      overflow-x: scroll;
      white-space: nowrap;
      padding: 10px 10px 10px 10px;
      top: 0px;
      position: sticky;
      text-align: left;
    }

    .bottom-buttons {
      width: calc(100% - 20px);
      right: 0px;
      table-layout: fixed;
      padding: 5px 10px 0px 10px;
      padding-bottom: env(safe-area-inset-bottom);
      bottom: 0px;
      top: auto;
      background: var(--main-color-opaque);
      -webkit-backdrop-filter: blur(7px);
      backdrop-filter: blur(7px);
    }

    .bottom-buttons .button-cell.active {
      font-weight: 800;
    }

    .bottom-buttons .button-cell.go-home-nav-element {
      display: block;
    }

    .bottom-buttons .button {
      height: auto;
      line-height: 10px;
      margin: 0px;
      padding: 10px 10px 5px 10px;
      color: var(--button-bg);
      background: none;
      box-shadow: none;
    }

    .bottom-buttons .button-tag {
      display: block;
      line-height: 20px;
      margin: 0px;
      padding: 5px 0px 0px 0px;
      font-size: .5em;
      font-weight: 800;
      opacity: .8;
    }

    .filter-view-toggle {
      display: block;
    }

    .no-offers-search {
      padding-top: 0px;
    }

    .no-offers {
      padding-top: 0px;
      padding-left: 0px;
    }
  }

  @media screen and (max-width: 790px) {
    .flex-item {
      min-width: 220px;
    }
  }

  @media screen and (max-width: 505px) {
    .flex-container {
      padding-left: 10px;
    }

    .flex-item {
      min-width: 120px;
      width: calc(50% - 10px);
      margin: 0px 10px 10px 0px;
      font-size: .8em;
    }

    .product-image {
      height: 70px !important;
    }

    .even-geduld-image {
      height: 70px !important;
    }

    .product-image img {
      max-width: 100px !important;
    }

    .product-deal {
      padding: 10px 15px 10px 15px !important;
    }
  }
}

/* menu to the side, large screen */
@media screen and (min-width: 850px) {

  .flex-container {
    position: relative;
    max-width: 2500px;
    padding: 60px 0px 0px 15px;
    margin: 0px;
  }

  .regular-container {
    position: relative;
    max-width: 2500px;
    padding: 60px 15px 0px 15px;
    margin: 0px;
  }

  @media screen and (min-width: 1024px) {
    .regular-container {
      position: relative;
        max-width: 2500px;
        padding: 60px 0px 0px 15px;
        margin: 0px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
      }
    }

  #filter-dialog {
    padding: 15px 0px 0px 0px;
  }

  .filter {
    background: none;
    float: left;
    width: 250px;
    padding: 45px 0px 0px 15px;
    position: sticky;
  }

  .filter-wrap {
    position: relative;
    padding-top: 0px;
    top: 0px;
    width: 250px;
    margin: 0px;
    float: right;
  }

  .not-sticky {
    position: relative;
  }

  .filter-deal,
  .filter-cat,
  .filter-shop {
    white-space: normal;
    text-align: right;
    width: 100%;
    padding: 0px 0px 0px 0px;
  }

  .filter-deal button,
  .filter-cat .cat-list button,
  .filter-shop button {
    padding: 17px 0px 17px 10px;
    line-height: 0;
    vertical-align: middle;
    margin: 0px 0px 5px 5px;
  }

  .filter-shop button:last-child,
  .filter-cat .cat-list button:last-child,
  .filter-deal button:last-child {
    margin: 0px 0px 5px 5px;
  }

  .filter-deal button {
    padding: 14px 10px 14px 10px;
  }

  .filter-cat .cat-list button.filter-no-interest:not(#active-category) {
    filter: grayscale();
    color: var(--filter-no-interest-color);
    background: var(--filter-no-interest-bg);
    padding: 17px 10px 17px 10px;
    line-height: 0;
    vertical-align: middle;
    margin: 0px 0px 5px 5px;
  }

  .filter-cat .cat-list #active-category.filter-no-interest {
    padding: 17px 10px 17px 10px;
  }

  .filter-shop button.filter-shop-no-interest:not(#active-category) {
    filter: grayscale();
    color: var(--filter-no-interest-color);
    background: var(--filter-no-interest-bg);
    padding: 17px 10px 17px 10px;
    line-height: 0;
    vertical-align: middle;
    margin: 0px 0px 5px 5px;
  }

  .filter-shop #active-shop.filter-shop-no-interest {
    padding: 17px 10px 17px 10px;
  }

  #active-deal,
  #active-shop,
  #active-category {
    right: 0px;
  }

}

/* menu back to the top, mobile interface */
@media screen and (max-width: 850px) {
  .bookmark-info {
    padding: 10px 0px 10px 0px;
    display: block;
    text-align: center;
  }
}

@keyframes showUp {
  0% {
    opacity: 0;
    transform: scale(.97);
  }

  50% {
    opacity: 0;
    transform: scale(.97);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes modal {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes dotdotdot {
  10% {
    content: ".........";
  }

  20% {
    content: "........";
  }

  30% {
    content: ".......";
  }

  40% {
    content: "......";
  }

  50% {
    content: ".....";
  }

  60% {
    content: "....";
  }

  70% {
    content: "...";
  }

  80% {
    content: "..";
  }

  90% {
    content: ".";
  }
}

@keyframes jumpy {
  0% {
    transform: translateY(0px);
  }

  20% {
    transform: translateY(-30px);
  }

  40% {
    transform: translateY(0px);
  }

  60% {
    transform: translateY(-30px);
  }

  80% {
    transform: translate(0px);
  }

  100% {
    transform: translateY(0px);
  }
}

@keyframes floatIn {
  0% {
    opacity: 0;
    transform: translateY(-15px);
  }

  10% {
    opacity: 1;
    transform: translateY(0px);
  }

  90% {
    opacity: 1;
    transform: translateY(0px);
  }

  100% {
    opacity: 0;
    transform: translateY(15px);
  }
}

@keyframes popUpDialog {
  0% {
    bottom: -100%;
  }

  100% {
    bottom: 0px;
  }
}

@keyframes popout {
  0% {
    transform: scale(.993)
  }

  50% {
    transform: scale(1.007);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes showFilters {
  0% {
    right: 2vw;
    opacity: 0;
  }

  50% {
    right: 2vw;
    opacity: 0;
  }

  100% {
    right: 0px;
    opacity: 1;
  }
}

@keyframes attention {
  0% {
    transform: translateY(0px);
  }

  95% {
    transform: translateY(0px);
  }

  97% {
    transform: translateY(10px);
  }

  99% {
    transform: translateY(-2px);
  }

  100% {
    transform: translateY(0px);
  }
}